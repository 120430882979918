@import url('https://fonts.googleapis.com/css2?family=Neucha&display=swap');

.App {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: teal;
    font-family: "Neucha", cursive;

}
.heading {
    text-transform: uppercase;
    font-size: 40px;
    margin: 30px 0;
    color: aliceblue;
    z-index: 1;
    text-align: center;
}

@media (max-width:800px) {
    .heading {
    margin: 15px 0;
    font-size: 35px;
}
}