.input {
    display: flex;
    width: 90%;
    position: relative;
    align-items: center;
}
.input__box {
    width: 100%;
    border-radius: 50px;
    padding: 20px 30px;
    font-size: 25px;
    border: none;
    transition: 0.2s;
    box-shadow: inset 0 0 5px black;
}

.input__box:focus {
    box-shadow: 0 0 10px 1000px rgba(0, 0, 0, 0.5);
    outline: none;
}
.input_submit {
    position: absolute;
    width: 50px;
    height: 50px;
    margin: 12px;
    border-radius: 50px;
    right: 0px;
    border: none;
    font-size: 15px;
    background-color: teal;
    color: white;
    transition: 0.2s all;
    box-shadow: 0 0 10px black;
}
.input_submit:hover {
    background-color: aquamarine;
}
.input_submit:active {
    transform: scale(0.8);
    box-shadow: 0 0 5px black;
}